import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';

const SelectAllButton = ({ text, ...rest }) => (
  <Button variant="contained" color="primary" aria-label="clear" {...rest}>
    <ClearIcon />
    {text}
  </Button>
);

SelectAllButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SelectAllButton;
