import React, { useEffect } from 'react';
import {
  Switch, Route, useLocation, useRouteMatch,
} from 'react-router-dom';
import EmailDigests from '../../components/emailDigests/EmailDigests';
import EmailDigest from '../../components/emailDigest/EmailDigest';

const EmailDigestContainer = () => {
  const location = useLocation();
  const { path } = useRouteMatch();
  useEffect(() => {
    // fetchData(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <Switch>
      <Route exact path={path}>
        <EmailDigests />
      </Route>
      <Route path={`${path}/add`} exact>
        <EmailDigest />
      </Route>
      <Route path={`${path}/:emailDigestId`} exact>
        <EmailDigest />
      </Route>
      <Route>
        Not found
      </Route>
    </Switch>
  );
};

EmailDigestContainer.propTypes = {

};

export default EmailDigestContainer;
