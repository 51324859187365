import * as actionTypes from '../../constants/actionTypes';

export const interviewsRequest = () => ({
  type: actionTypes.INTERVIEWS_REQUEST,
});

export const interviewsSuccess = (data) => ({
  type: actionTypes.INTERVIEWS_SUCCESS,
  data,
});

export const interviewsFailure = (errors) => ({
  type: actionTypes.INTERVIEWS_FAILURE,
  errors,
});

// thunks
export const fetchInterviews = (queryString) => async (dispatch) => {
  try {
    dispatch(interviewsRequest());
    await fetch(`${process.env.REACT_APP_API_ROOT}/interviews/${queryString || ''}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
    })
      .then((res) => res.json())
      .then((data) => dispatch(interviewsSuccess(data)));
  } catch (e) {
    dispatch(interviewsFailure());
    throw new Error();
  }
};
