import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link, useRouteMatch } from 'react-router-dom';

const EditorListRow = ({
  id, firstName, lastName, email, status, noOfEditors,
}) => {
  const { path } = useRouteMatch();
  return (
    <TableRow>
      <TableCell>
        <Link to={`${path}/${id}`}>{firstName}</Link>
      </TableCell>
      <TableCell>{lastName}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{noOfEditors}</TableCell>
    </TableRow>
  );
};

EditorListRow.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  noOfEditors: PropTypes.number.isRequired,
};

export default EditorListRow;
