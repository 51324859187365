/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { doSignOut } from '../../store/actions/auth';
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// eslint-disable-next-line react/prop-types
function PrivateRoute({ children, ...rest }) {
  const dispatch = useDispatch();
  let auth = false;
  const token = localStorage.getItem('access');
  if (token) {
    const decoded = jwtDecode(token);
    if (decoded.exp < new Date() / 1000) {
      dispatch(doSignOut());
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
    } else {
      auth = true;
    }
  }
  return (
    <Route
      {...rest}
      render={({ location }) => (auth ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: location },
          }}
        />
      ))}
    />
  );
}

export default PrivateRoute;
