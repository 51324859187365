import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';
import EnhancedTableHead from './EnhancedTableHead';

const PaginatedTable = ({
  headersRow, totalCount, children,
}) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [limit, setLimit] = useState(parseInt(searchParams.get('limit'), 0) || 10);

  const currOffset = parseInt(searchParams.get('offset'), 0);
  const page = currOffset > 0 ? parseInt(currOffset / limit, 0) : 0;

  const [currPage, setCurrPage] = useState(page);
  const [order, setOrder] = useState('-');
  const [orderBy, setOrderBy] = useState('created_at');
  const [ordering, setOrdering] = useState(searchParams.get('ordering') || `${order}${orderBy}`);

  const pageSizes = [5, 10, 15, 25, 50, 100];

  useEffect(() => {
    searchParams.set('limit', limit);
    searchParams.set('offset', currPage * limit);
    searchParams.set('ordering', ordering);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, currPage, ordering]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === '-';
    const newOrder = isDesc ? '' : '-';
    setOrder(newOrder);
    setOrderBy(property);
    setOrdering(`${newOrder}${property}`);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="custom pagination table">
          <EnhancedTableHead
            order={order === '-' ? 'desc' : 'asc'}
            orderBy={orderBy}
            rows={headersRow}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {children}
          </TableBody>
          <TableFooter>
            <TableRow />
          </TableFooter>
        </Table>
      </TableContainer>
      <Grid container justify="flex-start">
        <TablePagination
          component="div"
          rowsPerPageOptions={pageSizes}
          count={totalCount}
          rowsPerPage={limit}
          page={currPage}
          onChangePage={(event, p) => setCurrPage(p)}
          onChangeRowsPerPage={(event) => setLimit(event.target.value)}
        />
      </Grid>
    </>
  );
};

PaginatedTable.defaultProps = {
  totalCount: 0,
  children: '',
};

PaginatedTable.propTypes = {
  headersRow: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

export default PaginatedTable;
