import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  auth: false,
  errors: {},
};

// eslint-disable-next-line import/prefer-default-export
export const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SIGN_IN:
      return { ...state, auth: true, errors: {} };
    case actionTypes.AUTH_SIGN_OUT:
      return { ...state, auth: false, errors: {} };
    case actionTypes.AUTH_SIGN_IN_FAILED:
      return { ...state, auth: false, errors: action.errors };
    default:
      return state;
  }
};
