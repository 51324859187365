import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router-dom';
import PageHead from '../baseComponents/PageHead';
import SaveButton from '../buttons/SaveButton';
import EditorForm from './partials/EditorForm';
import useDataApi from '../../utils/useDataApi';
import LinearProgressBar from '../progress/LinearProgressBar';

const Editor = () => {
  const url = `${process.env.REACT_APP_API_ROOT}/editors/`;
  const {
    data, isFetching, errors, loaded, handleGet, handlePost, handlePut,
  } = useDataApi();
  const { editorId } = useParams();
  const history = useHistory();
  const handleSave = (values) => {
    if (editorId) {
      handlePut(`${url}${editorId}/`, values);
    } else {
      handlePost(url, values);
    }
  };

  useEffect(() => {
    if (editorId) {
      handleGet(`${url}${editorId}/`);
    }
  }, []);

  useEffect(() => {
    if (!editorId && data.id) {
      history.push({ pathname: `/editors/${data.id}` });
    }
  }, [data]);
  return (
    <>
      <Form
        onSubmit={handleSave}
        initialValues={data || {}}
        render={({
          handleSubmit, pristine, form, submitting, values,
        }) => (
          <form onSubmit={handleSubmit}>
            <PageHead title={data.id
              ? `${data.first_name} ${data.last_name}`
              : 'Add New Editor'}
            >
              <Grid item>
                <SaveButton text="Save" type="submit" disabled={submitting || pristine} />
              </Grid>
            </PageHead>
            <EditorForm />
          </form>
        )}
      />
      {isFetching
      && <LinearProgressBar />}
    </>
  );
};

export default Editor;
