import React from 'react';
import PropTypes from 'prop-types';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import Button from '@material-ui/core/Button';

const SelectAllButton = ({ text, ...rest }) => (
  <Button variant="contained" color="primary" aria-label="select all" {...rest}>
    <SelectAllIcon />
    {text}
  </Button>
);

SelectAllButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SelectAllButton;
