import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Switch, Route, useLocation, useRouteMatch,
} from 'react-router-dom';
import { fetchInterviews } from '../../store/actions/interviews';
import { getInterviews } from '../../store/selectors/interviews';
import InterviewList from '../../components/interviewsList/InterviewList';
import Interview from '../../components/interview/Interview';

const InterviewsListContainer = ({ fetchData, interviews }) => {
  const location = useLocation();
  const { path } = useRouteMatch();
  useEffect(() => {
    fetchData(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <Switch>
      <Route exact path={path}>
        <InterviewList interviews={interviews.results} count={interviews.count} />
      </Route>
      <Route path={`${path}/:interviewSlug`}>
        <Interview />
      </Route>
      <Route>
        Not found
      </Route>
    </Switch>
  );
};

InterviewsListContainer.propTypes = {
  fetchData: PropTypes.func.isRequired,
  interviews: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  interviews: getInterviews(state),
});

export default connect(
  mapStateToProps,
  { fetchData: fetchInterviews },
)(InterviewsListContainer);
