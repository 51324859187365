import React from 'react';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Button from '@material-ui/core/Button';

const LinkNewTab = ({ href, text, ...rest }) => (
  <Button
    href={href}
    target="_blank"
    variant="contained"
    aria-label="add"
    rel="noopener"
    {...rest}
  >
    <OpenInNewIcon />
    {text}
  </Button>
);

LinkNewTab.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default LinkNewTab;
