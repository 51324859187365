import * as actionTypes from '../../constants/actionTypes';

const initialState = {
  interviews: { count: 0, results: [] },
  isLoading: false,
};

// eslint-disable-next-line import/prefer-default-export
export const interviews = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INTERVIEWS_REQUEST:
      return {
        ...state,
        isLoading: true,
        interviews: initialState.interviews,
      };
    case actionTypes.INTERVIEWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interviews: action.data,
      };
    case actionTypes.INTERVIEWS_FAILURE:
      return {
        ...state,
        isLoading: false,
        interviews: initialState.interviews,
      };
    default:
      return state;
  }
};
