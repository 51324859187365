import React from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

const SaveButton = ({ text, ...rest }) => (
  <Button variant="contained" color="primary" aria-label="save" {...rest}>
    <SaveIcon />
    {text}
  </Button>
);

SaveButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SaveButton;
