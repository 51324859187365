import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link, useRouteMatch } from 'react-router-dom';

const EmailDigestRow = ({
  id, subject, status, createdAt, sentAt,
}) => {
  const { path } = useRouteMatch();
  return (
    <TableRow>
      <TableCell>
        <Link to={`${path}/${id}`}>{subject}</Link>
      </TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{createdAt}</TableCell>
      <TableCell>{sentAt}</TableCell>
    </TableRow>
  );
};

EmailDigestRow.defaultProps = {
  sentAt: '',
};

EmailDigestRow.propTypes = {
  id: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  sentAt: PropTypes.string,
};

export default EmailDigestRow;
