import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

const logger = createLogger({
  collapsed: true,
});

const middlewares = [thunk];
let enchancers;

if (process.env.REACT_APP_DEBUG) {
  middlewares.push(logger);
  enchancers = composeWithDevTools(applyMiddleware(...middlewares));
} else {
  enchancers = applyMiddleware(...middlewares);
}

const store = createStore(rootReducer, enchancers);

export default store;
