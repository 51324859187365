import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  progress: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99999,
  },
}));
const LinearProgressBar = () => {
  const classes = useStyles();
  return (
    <LinearProgress className={classes.progress} color="secondary" />
  );
};

export default LinearProgressBar;
