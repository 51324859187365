import React from 'react';
import PropTypes from 'prop-types';
import SendIcon from '@material-ui/icons/Send';

import Button from '@material-ui/core/Button';

const SendButton = ({ text, ...rest }) => (
  <Button
    variant="contained"
    aria-label="add"
    {...rest}
  >
    <SendIcon />
    {text}
  </Button>
);

SendButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SendButton;
