import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';

const AddButton = ({ text, ...rest }) => (
  <Button variant="contained" color="primary" aria-label="add" {...rest}>
    <AddIcon />
    {text}
  </Button>
);

AddButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default AddButton;
