import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';

const Interview = () => {
  const { interviewSlug } = useParams();
  const [interviewObj, setInterviewObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    //
  };

  const fetchInterview = () => {
    try {
      fetch(`${process.env.REACT_APP_API_ROOT}/interviews/${interviewSlug}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setInterviewObj(data));
    } catch (e) {
      throw new Error();
    }
  };
  useEffect(() => {
    fetchInterview();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {interviewSlug}
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={interviewObj}
        render={({
          handleSubmit, pristine, form, submitting, values,
        }) => (
          <form onSubmit={handleSubmit}>
            {isLoading && <div className="loading" />}
            <div>
              <Field
                name="title"
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Title"
                    error={(meta.error || meta.submitError) && meta.touched}
                    helperText={(meta.error || meta.submitError) && meta.touched && (
                      meta.error || meta.submitError
                    )}
                  />
                )}
              </Field>
            </div>
            <div>
              <Field
                name="perspective"
              >
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Perspective"
                    error={(meta.error || meta.submitError) && meta.touched}
                    helperText={(meta.error || meta.submitError) && meta.touched && (
                      meta.error || meta.submitError
                    )}
                  />
                )}
              </Field>
            </div>
            <div className="buttons">
              <button type="submit" disabled={submitting || pristine}>
                Submit
              </button>
              <button
                type="button"
                onClick={form.reset}
                disabled={submitting || pristine}
              >
                          Reset
              </button>
            </div>
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </>
  );
};

Interview.propTypes = {

};

export default Interview;
