import { combineReducers } from 'redux';

import { auth } from './auth';
import { interviews } from './interviews';

const rootReducer = combineReducers({
  app: auth,
  interviews,
});

export default rootReducer;
