import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

const TinyMCE = ({ body, onChange }) => (
  <Editor
    apiKey="bc7sbzjqzpwzuyhrzkfsjcz0mtg3lql23wrxyi1i6wfbpavj"
    initialValue={body}
    init={{
      height: 300,
      menubar: false,
      fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar: 'undo redo | formatselect | bold italic underline backcolor | '
                 + 'alignleft aligncenter alignright alignjustify | '
                 + 'fontselect | fontsizeselect | link |'
                 + 'bullist numlist outdent indent | removeformat | help',
    }}
    onEditorChange={onChange}
  />
);

TinyMCE.defaultProps = {
  body: '',
  onChange: () => {},
};

TinyMCE.propTypes = {
  body: PropTypes.string,
  onChange: PropTypes.func,
};

export default TinyMCE;
