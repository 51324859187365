import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PaginatedTable from '../table/PaginatedTable';
import InterviewRow from './partials/InterviewRow';
import InterviewFilters from './partials/InterviewFilters';

const rows = [
  {
    id: 'interview_id',
    label: 'Interview ID',
  },
  {
    id: 'ticker',
    label: 'Ticker',
  },
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'perspective',
    label: 'Perspective',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'call_date',
    label: 'Call Date',
  },
  {
    id: 'relevant_role',
    label: 'Relevant Role',
  },
  {
    id: 'visibility',
    label: 'Visibility',
  },
  {
    id: 'expert',
    label: 'Expert',
  },
  {
    id: 'analyst',
    label: 'Analyst',
  },
  {
    id: 'slug',
    label: 'Slug',
  },
  {
    id: 'audio',
    label: 'Audio',
  },
  {
    id: 'created_at',
    label: 'Created At',
  },
  {
    id: 'updated_at',
    label: 'Updated At',
  },
];


const InterviewsList = ({
  count, interviews,
}) => {
  // TODO
  const handleFilter = () => {};
  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Interviews
      </Typography>

      <Box mb={2} width={1}>
        <InterviewFilters />
      </Box>

      <PaginatedTable
        fetchData={() => ''}
        headersRow={rows}
        totalCount={count}
      >
        {interviews.map((interview) => (
          <InterviewRow
            key={interview.id}
            relevantRole={interview.relevant_role}
            audio={interview.audio}
            callDate={interview.call_date}
            visibility={interview.visibility}
            perspective={interview.perspective}
            status={interview.status}
            createdAt={interview.created_at}
            slug={interview.slug}
            analyst={interview.analyst.name}
            updatedAt={interview.updated_at}
            interviewId={interview.interview_id}
            expert={interview.expert.name}
            ticker={interview.ticker.name}
            title={interview.relevant_role}
          />
        ))}
      </PaginatedTable>
    </>
  );
};
InterviewsList.propTypes = {
  count: PropTypes.number.isRequired,
  interviews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default InterviewsList;
