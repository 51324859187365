import Paper from '@material-ui/core/Paper';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MomentUtils from '@date-io/moment';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { inputDateFormat } from '../../../constants/dateFormats';
import EmailDigestInterview from './EmailDigestInterview';
import SelectAllButton from '../../buttons/SelectAllButton';
import ClearButton from '../../buttons/ClearButton';
import AddButton from '../../buttons/AddButton';
import useDataApi from '../../../utils/useDataApi';
import TinyMCE from '../../tinyMCE/TinyMCE';
import EmailDigestDatePickers from './EmailDigestDatePickers';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {Component} from 'react';

const EmailDigestForm = React.forwardRef(({
  digestSubject, digestBody, digestAddedInterviews, templates, onSave, order,
}, ref) => {
  const dateMoment = new MomentUtils();
  const dateNow = dateMoment.date();
  const location = useLocation();
  const [subject, setSubject] = useState(digestSubject);
  const [body, setBody] = useState(digestBody);
  const [dateFrom, setDateFrom] = useState(dateNow);
  const [dateTo, setDateTo] = useState(dateNow);
  const [selectedInterviews, setSelectedInterviews] = useState([]);
  const [addedInterviews, setAddedInterviews] = useState(digestAddedInterviews);

  const {
    data: interviews,
    isFetching: isInterviewsFetching,
    errors: interviewsErrors,
    loaded: interviewsLoaded,
    handleGet: getInterviews,
  } = useDataApi();

  const fetchInterviews = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('published_from', dateFrom.format(inputDateFormat));
    searchParams.set('published_to', dateTo.format(inputDateFormat));
    const url = `${process.env.REACT_APP_API_ROOT}/interviews/?${searchParams.toString()}`;
    getInterviews(url);
  };

  const handleSelectAll = () => {
    if (interviews.results) {
      setSelectedInterviews(interviews.results);
    }
  };

  const handleClearSelection = () => {
    setSelectedInterviews([]);
  };

  const handleInterviewClick = (interview) => {
    if (selectedInterviews.includes(interview)) {
      const intr = [...selectedInterviews];
      intr.splice(intr.indexOf(interview), 1);
      setSelectedInterviews(intr);
    } else {
      setSelectedInterviews([...selectedInterviews, interview]);
    }
  };

  const handleSave = () => {
    const bodyData = {
      subject,
      body,
      interviews: interviewsCopy.map((interview) => interview.id),
      templates: templates
    };
    onSave(bodyData);
  };
  useImperativeHandle(ref, () => ({ handleSave }));

  useEffect(() => {
    fetchInterviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchInterviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  function cmp(a, b){
    // nulls sort after anything else
    if (a.call_quality === null) {
      return 1;
    }
    else if (b.call_quality === null) {
        return -1;
    }

    // dict for priorities to understand how to sort
    let priority = {
      'Great': 3,
      'Good': 2,
      'Average': 1,
      'Bad': 0,
    };

    if (priority[a.call_quality] === priority[b.call_quality] ) {
      return 0;
    }

    // otherwise, if we're ascending, lowest sorts first
    else if (order == "ASC") {
        return priority[a.call_quality] < priority[b.call_quality] ? -1 : 1;
    }
    // if descending, highest sorts first
    else { 
        return priority[a.call_quality] < priority[b.call_quality] ? 1 : -1;
    }

  }

  const [interviewsCopy, setInterviewsCopy] = useState([]);

  useEffect(() => {
    const sortArray = () => {
      if (addedInterviews && addedInterviews.length > 0) {
        const sorted = [...addedInterviews].sort(cmp);
        setInterviewsCopy(sorted);
      }
    };   
    sortArray();
  }, [order, addedInterviews]);

const SortableInterviewItem = SortableElement((interview) => 
  <li tabIndex={0} style={{listStyle:'none'}}>
    <EmailDigestInterview
      key={interview.value.id}
      title={interview.value.relevant_role}
      perspective={interview.value.perspective}
      transcriptionStatus={interview.value.transcription_status}
      callDate={interview.value.call_date}
      callQuality={interview.value.call_quality}
      publishedAt={interview.value.published_at}
    />
  </li>
);

const SortableInterviewList = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableInterviewItem key={`item-${value.id}`} index={index} value={value} />
      ))}
    </ul>
  );
});

class SortableComponent extends Component {
  onSortEnd = ({oldIndex, newIndex}) => {
      setInterviewsCopy(arrayMove(interviewsCopy, oldIndex, newIndex));
  };

  render() {
    return <SortableInterviewList items={interviewsCopy} onSortEnd={this.onSortEnd} />;
  }
}
  return (
    <>
      {/* {console.log(templates && Object.values(templates))} */}
      <Box mt={2} width={1}>
        <Grid container spacing={2} component={Paper}>
          <Grid item xs={12} md={6}>
            <TextField
              name="subject"
              label="Subject"
              fullWidth
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
            />
            <Box mt={2} mb={2}>
              <TinyMCE body={body} onChange={setBody} />
            </Box>
            {order == 'manual' ? (
                <SortableComponent />
              ):
              (
              interviewsCopy.map((interview) => (
                <EmailDigestInterview
                  key={interview.id}
                  title={interview.relevant_role}
                  perspective={interview.perspective}
                  transcriptionStatus={interview.transcription_status}
                  callDate={interview.call_date}
                  callQuality={interview.call_quality}
                  publishedAt={interview.published_at}
                />
            )))
          }
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={2} width={1}>
              <EmailDigestDatePickers
                dateFrom={dateFrom}
                onChangeDateFrom={setDateFrom}
                dateTo={dateTo}
                onChangeDateTo={setDateTo}
              />
            </Box>
            <Grid container>
              <Box mb={2} width={1}>
                <Grid item xs={12}>
                  <Grid container spacing={2} justify="flex-end">
                    {selectedInterviews.length > 0 && (
                    <Grid item>
                      <AddButton text="Add to Digest" onClick={() => setAddedInterviews(selectedInterviews)} />
                    </Grid>
                    )}
                    <Grid item>
                      {selectedInterviews.length > 0
                        ? <ClearButton text="Clear Selection" onClick={handleClearSelection} />
                        : <SelectAllButton text="Select All" onClick={handleSelectAll} />}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Grid item xs={12}>
                {/* eslint-disable-next-line array-callback-return,consistent-return */}
                {interviews.results && interviews.results.map((interview) => {
                  if (!addedInterviews.includes(interview)) {
                    return (
                      <EmailDigestInterview
                        key={interview.id}
                        onClick={() => handleInterviewClick(interview)}
                        title={interview.relevant_role}
                        perspective={interview.perspective}
                        transcriptionStatus={interview.transcription_status}
                        callDate={interview.call_date}
                        callQuality={interview.call_quality}
                        publishedAt={interview.published_at}
                        isSelected={selectedInterviews.includes(interview)}
                      />
                    );
                  }
                })}
                {isInterviewsFetching && <CircularProgress />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

EmailDigestForm.defaultProps = {
  digestSubject: '',
  digestBody: '',
  digestAddedInterviews: [],
};

EmailDigestForm.propTypes = {
  digestSubject: PropTypes.string,
  order: PropTypes.string,
  digestBody: PropTypes.string,
  digestAddedInterviews: PropTypes.arrayOf(PropTypes.object),
  onSave: PropTypes.func.isRequired,
};

export default EmailDigestForm;
