import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const PageHead = ({ title, children }) => (
  <Grid container justify="space-between">
    <Grid item>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {title}
      </Typography>
    </Grid>
    {children
    && (
    <Grid item>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Grid>
    )}
  </Grid>
);

PageHead.defaultProps = {
  children: undefined,
};

PageHead.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
};

export default PageHead;
