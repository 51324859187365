import React from 'react';
import PropTypes from 'prop-types';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

import Button from '@material-ui/core/Button';

const SortButton = ({ text, ...rest }) => (
  <Button variant="contained" color="primary" aria-label="sort" {...rest}>
    <UnfoldMoreIcon />
    {text}
  </Button>
);

SortButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SortButton;
