import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

const InterviewFilters = () => (
  <Grid container spacing={2}>
    <Grid item xs sm={3}>
      <TextField
        id="filled-required"
        label="Type to search..."
        helperText="Search by Title, Perspective and something else"
        fullWidth
      />
    </Grid>
    <Grid item xs sm={3}>
      <Autocomplete
        options={[]}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Analyst"
            fullWidth
          />
        )}
      />
    </Grid>
    <Grid item xs sm={3}>
      <Autocomplete
        options={[]}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Expert"
            fullWidth
          />
        )}
      />
    </Grid>
  </Grid>
);

InterviewFilters.propTypes = {

};

export default InterviewFilters;
