import React from 'react';

const Dashboard = () => (
  <div>
      some useful info will be here soon
  </div>
);


export default Dashboard;
