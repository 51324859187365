import React, { useEffect } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddButton from '../buttons/AddButton';
import PaginatedTable from '../table/PaginatedTable';
import EditorListRow from './partials/EditorListRow';
import LinearProgressBar from '../progress/LinearProgressBar';
import useDataApi from '../../utils/useDataApi';
import PageHead from '../baseComponents/PageHead';

const rows = [
  {
    id: 'first_name',
    label: 'First Name',
  },
  {
    id: 'last_name',
    label: 'Last Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'no_of_editors',
    label: 'Number of Editors',
  },
];

const EditorsList = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const {
    data, isFetching, errors, loaded, handleGet,
  } = useDataApi();

  const fetchData = () => {
    const searchParams = new URLSearchParams(location.search);
    const url = `${process.env.REACT_APP_API_ROOT}/editors/?${searchParams.toString()}`;
    handleGet(url);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [location.search]);

  return (
    <>
      <PageHead title="Editors">
        <Grid item>
          <AddButton component={Link} to={`${path}/add`} text="Add" />
        </Grid>
      </PageHead>
      <Box mt={2} width={1}>
        {data.results
        && (
        <PaginatedTable
          headersRow={rows}
          totalCount={data.count}
        >
          {data.results && data.results.map((item) => (
            <EditorListRow
              key={item.id}
              id={item.id}
              email={item.email}
              firstName={item.first_name}
              lastName={item.last_name}
              noOfEditors={item.no_of_editors}
              status={item.status}
            />
          ))}
        </PaginatedTable>
        )}

        {isFetching
         && <LinearProgressBar />}
      </Box>
    </>
  );
};

EditorsList.propTypes = {

};

export default EditorsList;
