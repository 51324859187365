import React, { useEffect } from 'react';
import {
  Switch, Route, useLocation, useRouteMatch,
} from 'react-router-dom';
import EditorsList from '../../components/editorsList/EditorsList';
import Editor from '../../components/editor/Editor';


const EditorsListContainer = () => {
  const location = useLocation();
  const { path } = useRouteMatch();
  useEffect(() => {
    // fetchData(location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <Switch>
      <Route exact path={path}>
        <EditorsList />
      </Route>
      <Route path={`${path}/add/`} exact>
        <Editor />
      </Route>
      <Route path={`${path}/:editorId`} exact>
        <Editor />
      </Route>
      <Route>
        Not found
      </Route>
    </Switch>
  );
};

EditorsListContainer.propTypes = {

};

export default EditorsListContainer;
