import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {Select, TextField} from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

const EditorForm = () => (
  <Paper>
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField label="First Name" name="first_name" required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Last Name" name="last_name" required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Email" name="email" required type="email" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select name="status" label="Status">
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField label="Number of Editors" name="no_of_editors" required type="number" />
        </Grid>
      </Grid>
    </Box>
  </Paper>
);

export default EditorForm;
