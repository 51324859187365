import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link, useRouteMatch } from 'react-router-dom';

const InterviewRow = ({
  interviewId, ticker, title, perspective, status, callDate, relevantRole,
  visibility, expert, analyst, slug, audio, createdAt, updatedAt,
}) => {
  const { path } = useRouteMatch();
  return (
    <TableRow>
      <TableCell>
        <Link to={`${path}/${slug}`}>{interviewId}</Link>
      </TableCell>
      <TableCell>{ticker}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>{perspective}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>{callDate}</TableCell>
      <TableCell>{relevantRole}</TableCell>
      <TableCell>{visibility}</TableCell>
      <TableCell>{expert}</TableCell>
      <TableCell>{analyst}</TableCell>
      <TableCell>{slug}</TableCell>
      <TableCell>{audio}</TableCell>
      <TableCell>{createdAt}</TableCell>
      <TableCell>{updatedAt}</TableCell>
    </TableRow>
  );
};

InterviewRow.defaultProps = {
  audio: '',
  relevantRole: '',
};

InterviewRow.propTypes = {
  interviewId: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  perspective: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  callDate: PropTypes.string.isRequired,
  relevantRole: PropTypes.string,
  visibility: PropTypes.string.isRequired,
  expert: PropTypes.string.isRequired,
  analyst: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  audio: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

export default InterviewRow;
