import { useEffect, useReducer, useState } from 'react';

const useDataApi = (initialData = {}) => {
  const REQUEST_INIT_VALUES = {
    url: '',
    method: 'GET',
  };
  const [requestState, setRequestState] = useReducer(
    // eslint-disable-next-line no-shadow
    (state, newState) => ({ ...state, ...newState }),
    REQUEST_INIT_VALUES,
  );

  const INIT_VALUES = {
    loaded: false,
    isFetching: false,
    data: initialData,
    errors: null,
  };
  const [state, setState] = useReducer(
    // eslint-disable-next-line no-shadow
    (state, newState) => ({ ...state, ...newState }),
    INIT_VALUES,
  );

  const handleGet = (urlPath) => {
    setRequestState({ url: urlPath, method: 'GET' });
  };

  const handlePost = (urlPath, values) => {
    setRequestState({
      url: urlPath, body: JSON.stringify(values), method: 'POST',
    });
  };

  const handlePut = (urlPath, values) => {
    setRequestState({
      url: urlPath, body: JSON.stringify(values), method: 'PUT',
    });
  };

  useEffect(() => {
    const fetchData = () => {
      setState({ ...state, isFetching: true, loaded: false });
      const params = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access')}`,
        },
        ...requestState,
      };

      fetch(requestState.url, params)
        .then((res) => res.json())
        .then((data) => {
          setState({
            ...state, data, isFetching: false, loaded: true,
          });
        },
        (errors) => {
          setState({
            ...state, errors, isFetching: false, loaded: true,
          });
        });
    };
    fetchData();
  }, [requestState.url, requestState.method, requestState.body]);
  return {
    data: state.data,
    isFetching: state.isFetching,
    errors: state.errors,
    loaded: state.loaded,
    handleGet,
    handlePost,
    handlePut,
  };
};

export default useDataApi;
