import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import SignIn from './components/login/SignIn';
import PrivateRouter from './HOCs/routes/PrivateRouter';
import AdminEntry from './AdminEntry';

import store from './store';

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/signin" exact>
                <SignIn />
              </Route>
              <PrivateRouter path="/">
                <AdminEntry />
              </PrivateRouter>
              <Route>
              not found
              </Route>
            </Switch>
          </Router>
        </div>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
