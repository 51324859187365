export const AUTH_SIGN_IN = 'AUTH/SIGN_IN';
export const AUTH_SIGN_OUT = 'AUTH/SIGN_OUT';
export const AUTH_SIGN_IN_FAILED = 'AUTHSIGN_IN_FAILED';

export const INTERVIEWS_REQUEST = 'INTERVIEWS/REQUEST';
export const INTERVIEWS_SUCCESS = 'INTERVIEWS/SUCCESS';
export const INTERVIEWS_FAILURE = 'INTERVIEWS/FAILURE';

export const EMAIL_DIGEST_INTERVIEWS_REQUEST = 'EMAIL_DIGEST_INTERVIEWS/REQUEST';
export const EMAIL_DIGEST_INTERVIEWS_SUCCESS = 'EMAIL_DIGEST_INTERVIEWS/SUCCESS';
export const EMAIL_DIGEST_INTERVIEWS_FAILURE = 'EMAIL_DIGEST_INTERVIEWS/FAILURE';
