import React from 'react';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DomainIcon from '@material-ui/icons/Domain';
import RowingIcon from '@material-ui/icons/Rowing';
import InterviewsListContainer from '../containers/interviewsList/InterviewsListContainer';
import DashboardContainer from '../containers/dashboard/DashboardContainer';
import EditorsListContainer from '../containers/editorsList/EditorsListContainer';
import EmailDigestContainer from '../containers/emailDigest/emailDigestContainer';


const adminDrawerRoutes = [
  {
    path: '/',
    component: <DashboardContainer />,
    icon: <DashboardIcon />,
    title: 'Dashboard',
    exact: true,
  },
  // {
  //   path: '/interviews',
  //   component: <InterviewsListContainer />,
  //   icon: <LocalLibraryIcon />,
  //   title: 'Interviews',
  // },
  {
    path: '/editors',
    component: <EditorsListContainer />,
    icon: <RowingIcon />,
    title: 'Editors',
  },{
    path: '/email-digests',
    component: <EmailDigestContainer />,
    icon: <DomainIcon />,
    title: 'Email Digest',
  },

];

export default adminDrawerRoutes;
