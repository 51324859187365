import React, { useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { withSnackbar } from 'notistack';
import BackButton from '../buttons/BackButton';
import SortButton from '../buttons/SortButton';
import SaveButton from '../buttons/SaveButton';
import EmailDigestForm from './partials/EmailDigestForm';
import LinearProgressBar from '../progress/LinearProgressBar';
import LinkNewTab from '../buttons/LinkNewTab';
import SendButton from '../buttons/SendButton';
import PageHead from '../baseComponents/PageHead';
import useDataApi from '../../utils/useDataApi';
import Select from 'react-select'

const DEFAULT_BODY = '<p><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;">Hi %FIRSTNAME%! If you need a login, just let us know. Please find links to this morning\'s uploaded transcripts below. </span><span style="font-family: arial, helvetica, sans-serif; font-size: 12pt;">You can always view all of our transcripts here: <a href="https://stream.mosaicrm.com/">https://stream.mosaicrm.com/</a>.&nbsp;</span></p>';
const DEFAULT_SUBJECT = 'New Transcripts: ';

const EmailDigest = ({ enqueueSnackbar }) => {
  const digestFormRef = useRef();
  const history = useHistory();
  const { emailDigestId } = useParams();

  const {
    data, isFetching, errors, loaded, handleGet, handlePost, handlePut,
  } = useDataApi();

  const {
    data: sentDigestData,
    handleGet: sendDigest,
  } = useDataApi();

  const url = `${process.env.REACT_APP_API_ROOT}/email-digests/`;
  const handleSave = (bodyData) => {
    if (emailDigestId) {
      handlePut(`${url}${emailDigestId}/`, bodyData);
    } else {
      handlePost(url, bodyData);
    }
  };

  const handleSend = () => {
    const sendUrl = `${process.env.REACT_APP_API_ROOT}/email-digests/${data.uid}/send_digest/`;
    sendDigest(sendUrl);
  };

  useEffect(() => {
    if (emailDigestId) {
      handleGet(`${url}${emailDigestId}/`);
    }
  }, []);

  useEffect(() => {
    if (!emailDigestId && data.uid) {
      history.push({ pathname: `/email-digests/${data.uid}` });
    }
  }, [data]);

  useEffect(() => {
    if (sentDigestData.result_message) {
      enqueueSnackbar(sentDigestData.result_message, {
        variant: 'success',
      });
    }
  }, [sentDigestData]);


  const [order, setOrder] = useState('manual');
  const [orderIndex, setOrderIndex] = useState(0);


  const options = {
    'manual': 'Call quality - manual',
    'ASC': 'Call quality - asc',
    'DESC': 'Call quality - desc',
  };

  const sortInterviews = () => {
    const orderOptions = ['manual', 'ASC', 'DESC'];
    setOrderIndex(((orderIndex + 1) > 2 )? 0 : orderIndex+1);
    setOrder(orderOptions[orderIndex]);
  };

  const templates = [
    { value: 'transcript_digest', label: 'Transcript Digest' },
    { value: 'transcript_digest_new', label: 'New Transcript Digest Template' },
  ];

  const getDefaultValue = () => {
    // returns gefault value for select if user edits already existing digest
    const digestTemplates = data.templates?.split(',');

    if (digestTemplates.length > 1){
      return ([templates[0], templates[1]]);
    } else {
      return (templates.filter(a=>a.value === digestTemplates[0])) 
    }
  };

  const [selectedClient,setSelectedClient] = useState(!data.uid ? templates[0].value : getDefaultValue());

  const [isSplitTesting, setIsSplitTesting] = useState(false);

  function handleSelectChange(value) {
    if(value) {
      const values = value.map(a=>a.value);
      setSelectedClient(values.join());
    }
    else {
      enqueueSnackbar("Please select at least one email template", {
        variant: 'error',
        duration: 7000,
      });
    }
  }

  useEffect(()=>{
    const splitted = selectedClient.split(',');
    if (splitted.length > 1) {
      setIsSplitTesting(true);
      enqueueSnackbar("By selecting more than one template, you are switching to the daily digest A/B testing mode.", {
        variant: 'success',
        duration: 10000,
      });
    }
    else {
      setIsSplitTesting(false);
    }
  },[selectedClient]);

  const [templateName, setTemplateName] = useState(templates[0].value);

  const handlePreviewLinkChange = (value) => {
    setTemplateName(value.value);
  }

  useEffect(()=>{
    console.log(templateName);
  },[templateName]);

  return (
    <>
      <PageHead title={data.subject
        ? data.subject
        : 'Create New Digest'}
      >
        <Grid item>
          <BackButton onClick={() => history.goBack()} text="To List" />
        </Grid>
        {data.uid
        && (
          <>
            <Grid item>
              <SendButton
                onClick={handleSend}
                text="Send"
              />
            </Grid>
            <Grid item>
            <Box minWidth="180px">
              <Select
                options={templates}
                defaultValue={templates[0]}
                onChange={handlePreviewLinkChange}
              />
            </Box>
            </Grid>
            <Grid item>
              <LinkNewTab href={`${process.env.REACT_APP_SERVER_URL}/mail/digest/${data.uid}/${templateName}`} text="Preview" />
            </Grid>
          </>
        )}
        <Grid item>
          <SaveButton
            onClick={() => digestFormRef.current.handleSave()}
            text={data.uid
              ? 'Update'
              : 'Save'}
          />
        </Grid>
      </PageHead>

      <Box mt={2}>
        { emailDigestId ?  (
          <div>
            <div>Sort by:</div>
            <SortButton
                  onClick={sortInterviews}
                  text={options[order]}
            />
          </div> 
        ) : '' }
        {/* edit digest */}
        <Box mt="10px">
          {isSplitTesting && 
            <div>
              <b>
                Split testing mode
              </b>
            </div>
          }
          <div>
            Select template for digest
          </div>
          {
          !data.uid &&
            <div>
            <Select
              options={templates}
              defaultValue={templates[0]}
              onChange={handleSelectChange}
              isMulti
            />
            </div>
          }
           {data.status === 'draft' && getDefaultValue()
          && (
            <>
            <Select
              options={templates}
              defaultValue={getDefaultValue()}
              onChange={handleSelectChange}
              isMulti
            />
            </>
          )}
        </Box>
        {data.status === 'draft'
        && (
        <EmailDigestForm
          ref={(ins) => { digestFormRef.current = ins; }}
          onSave={handleSave}
          digestAddedInterviews={data.interviews}
          order={order}
          digestBody={data.body}
          digestSubject={data.subject}
        />
        )}

        {/* create new digest */}
        {!data.uid
        && (
        <EmailDigestForm
          ref={(ins) => { digestFormRef.current = ins; }}
          digestBody={DEFAULT_BODY}
          digestSubject={DEFAULT_SUBJECT}
          templates={selectedClient}
          onSave={handleSave}
        />
        )}
      </Box>
      {isFetching
      && <LinearProgressBar />}
    </>
  );
};

EmailDigest.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(EmailDigest);
