/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { doSignIn } from '../../store/actions/auth';
import { getAuth, getAuthErrors } from '../../store/selectors/auth';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = ({ auth, authErrors, handleSignIn }) => {
  const classes = useStyles();
  const onSubmit = async (values) => {
    await handleSignIn({ email: values.email, password: values.password });
  };

  return (
    <>
      {(auth || localStorage.getItem('access')) ? <Redirect to="/" /> : ''}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          Sign in
          </Typography>

          <Form
            onSubmit={onSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = 'Required';
              }
              if (!values.password) {
                errors.password = 'Required';
              }
              return errors;
            }}
            render={({
              handleSubmit,
              form,
              submitting,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <Field name="email">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={(meta.error || meta.submitError) && meta.touched}
                        helperText={(meta.error || meta.submitError) && meta.touched && (
                          meta.error || meta.submitError
                        )}
                      />
                    </>
                  )}
                </Field>
                <Field name="password">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={meta.error && meta.touched}
                        helperText={meta.error && meta.touched && meta.error}
                      />
                    </>
                  )}
                </Field>
                {Object.keys(authErrors).length > 0 && <Alert severity="error">{authErrors.detail}</Alert>}
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={submitting}
                >
                Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                    Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
};

SignIn.propTypes = {
  auth: PropTypes.bool.isRequired,
  authErrors: PropTypes.shape({
    detail: PropTypes.string,
  }).isRequired,
  handleSignIn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  authErrors: getAuthErrors(state),
});

export default withRouter(
  connect(
    mapStateToProps,
    { handleSignIn: doSignIn },
  )(SignIn),
);
