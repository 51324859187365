import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';

const BackButton = ({ text, ...rest }) => (
  <Button variant="contained" aria-label="back" {...rest}>
    <ArrowBackIcon />
    {text}
  </Button>
);

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BackButton;
