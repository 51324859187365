import React, { useEffect } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddButton from '../buttons/AddButton';
import PaginatedTable from '../table/PaginatedTable';
import EmailDigestRow from './partials/EmailDigestRow';
import LinearProgressBar from '../progress/LinearProgressBar';
import useDataApi from '../../utils/useDataApi';

const rows = [
  {
    id: 'subject',
    label: 'Subject',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'created_at',
    label: 'Created At',
  },
  {
    id: 'sent_at',
    label: 'Sent At',
  },
];

const EmailDigests = () => {
  const { path } = useRouteMatch();
  const location = useLocation();

  const {
    data, isFetching, errors, loaded, handleGet,
  } = useDataApi();

  const fetchData = () => {
    const searchParams = new URLSearchParams(location.search);
    const url = `${process.env.REACT_APP_API_ROOT}/email-digests/?${searchParams.toString()}`;
    handleGet(url);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [location.search]);
  return (
    <>
      <Grid container justify="flex-end">
        <Grid item>
          <AddButton component={Link} to={`${path}/add`} text="Add" />
        </Grid>
      </Grid>
      <Box mt={2} width={1}>
        {data.results
        && (
        <PaginatedTable
          headersRow={rows}
          totalCount={data.count}
        >
          {data.results.map((digest) => (
            <EmailDigestRow
              key={digest.id}
              id={digest.uid}
              subject={digest.subject}
              status={digest.status}
              createdAt={digest.created_at}
              sentAt={digest.sent_at}
            />
          ))}
        </PaginatedTable>
        )}

        {isFetching
         && <LinearProgressBar />}
      </Box>
    </>
  );
};

EmailDigests.propTypes = {

};

export default EmailDigests;
