import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Moment from 'react-moment';
import { dateFormat } from '../../../constants/dateFormats';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: '#e0e0e0',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const EmailDigestInterview = ({
  title, perspective, transcriptionStatus, callDate, publishedAt, isSelected, onClick, callQuality,
}) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, isSelected && classes.selected)} onClick={onClick}>
      <CardContent>
        <Typography variant="h6" component="h6">
          {title}
          {' | '}
          {perspective}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Transcription Status:
          {' '}
          {transcriptionStatus}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Call Date:
          {' '}
          {callDate
          && <Moment format={dateFormat}>{callDate}</Moment>}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Call quality:
          {' '}
          {callQuality}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Published At:
          {' '}
          {publishedAt
          && <Moment format={dateFormat}>{publishedAt}</Moment>}
        </Typography>
      </CardContent>
    </Card>
  );
};

EmailDigestInterview.defaultProps = {
  isSelected: false,
  onClick: () => {},
};

EmailDigestInterview.propTypes = {
  title: PropTypes.string.isRequired,
  perspective: PropTypes.string.isRequired,
  transcriptionStatus: PropTypes.string.isRequired,
  callDate: PropTypes.string.isRequired,
  callQuality: PropTypes.string,
  publishedAt: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default EmailDigestInterview;
