import React from 'react';
import PropTypes from 'prop-types';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Grid from '@material-ui/core/Grid';
import { dateFormat } from '../../../constants/dateFormats';

const EmailDigestDatePickers = ({
  dateFrom, onChangeDateFrom, dateTo, onChangeDateTo,
}) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <KeyboardDatePicker
          id="date-picker-from"
          label="From Date"
          format={dateFormat}
          value={dateFrom}
          onChange={onChangeDateFrom}
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <KeyboardDatePicker
          id="date-picker-to"
          label="To Date"
          format={dateFormat}
          value={dateTo}
          onChange={onChangeDateTo}
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </Grid>
  </MuiPickersUtilsProvider>
);

EmailDigestDatePickers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dateFrom: PropTypes.object.isRequired,
  onChangeDateFrom: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dateTo: PropTypes.object.isRequired,
  onChangeDateTo: PropTypes.func.isRequired,
};

export default EmailDigestDatePickers;
